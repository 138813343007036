<template>
  <v-container fluid>

    <v-tabs v-model="tab" class="mb-2">
      <v-tab>Estados estudio</v-tab>
      <v-tab>Tipos contacto</v-tab>
      <!-- <v-tab>Tipos estudio</v-tab> -->
    </v-tabs>

    <v-tabs-items class="pa-0" v-model="tab" >
      <v-tab-item :value="0" class="pa-0" >
        <ControlEstados />
      </v-tab-item>
      <v-tab-item :value="1" class="pa-0">
        <ControlTipos />
      </v-tab-item>
      <!-- <v-tab-item :value="2" class="pa-0">
        <ControlEstados />
      </v-tab-item> -->
    </v-tabs-items>

  </v-container>
</template>

<script>
export default {
  components: {
    ControlEstados: () => import('@/components/controles/ControlEstados.vue'),
    ControlTipos: () => import('@/components/controles/ControlTipos.vue')
  },
  data() {
    return {
      tab: 0
    };
  },
};
</script>

<style></style>
